// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
@import "_functions.scss";

//
// Color system
//
$theme-type :"web" !default;
// stylelint-disable
$white: #FFFFFF !default;
$gray-10: #f2f2f2 !default;
$gray-20: #E6E6E6 !default;
//$gray-20:  #D4D4D4 !default;
//$gray-80:  #848484 !default;
$gray-80: #A3A3A3 !default;
$gray-100: #404040 !default;
$gray-bg: #404040 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(("white": $white,
    "10": $gray-10,
    "20": $gray-20,
    "80": $gray-80,
    "100": $gray-100), $grays);



$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: () !default;
$colors: map-merge(("blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-80,
    "gray-dark": $gray-100), $colors);

$primary: #FF4232 !default;
$primary-light: #FF7165 !default;
$primary-dark: #FE1400 !default;
$secondary: #667e8a !default;
$safety: #081EB3 !default;
$success: #59C36E !default;
$info: $safety !default;
$warning: #F8D648 !default;
$danger: #BD272D !default;
$gray: $gray-80 !default;
$light: $gray-10 !default;
$dark: $gray-100 !default;

$theme-colors: () !default;
$theme-colors: map-merge(("primary": $primary,
    "secondary": $secondary,
    "safety": $safety,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "gray": $gray,
    "light": $light,
    "white": $white,
    "dark": $dark), $theme-colors);

$theme-bg-colors: () !default;
$theme-bg-colors: map-merge(("primary": $primary,
    "secondary": $secondary,
    "safety": $safety,
    "success": #e4fffe,
    "info": $info,
    "warning": #fff7e5,
    "danger": #ffebee,
    "light": $light,
    "white": $white,
    "dark": $dark), $theme-bg-colors);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.


$spacer: 20px !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: ($spacer * .75),
    4: $spacer,
    5: ($spacer * 1.5)),
  $spacers);

$space-xs: $spacer * .25;
$space-sm: $spacer * .5;
$space-md: $spacer * .75;
$space-base: $spacer;
$space-lg: $spacer * 1.5;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (25: 25%,
  50: 50%,
  75: 75%,
  100: 100%) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-10 !default;
$body-color: $gray-100 !default;

// Links
//
// Style anchor elements.

$link-color: theme-color("secondary") !default;
$link-decoration: none !default;
$link-hover-color: $gray-80 !default;
$link-hover-decoration: none !default;
$link-transition: color .2s !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem !default;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 40px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-20 !default;
$border-color-dark: $gray-80 !default;

$border-radius: 5px !default;
$border-radius-lg: 100px !default;
$border-radius-sm: .2rem !default;

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;

$caret-width: .3em !default;

$transition-base: all .2s ease-in-out !default;
$transition-fade: opacity .15s linear !default;
$transition-collapse: height .35s ease !default;


//$padding-lg:        30px!default;
//$padding-base:      20px!default;
//$padding-sm:        10px!default;
//$padding-xs:        5px!default;
//$margin-lg:         30px!default;
//$margin-base:       20px!default;
//$margin-sm:         10px!default;
//$margin-xs:         5px!default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'sans-font',
'Open Sans',
'Helvetica Neue',
Helvetica,
Tahoma,
Arial,
sans-serif !default;
$font-family-monospace: "SFMono-Regular",
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-icon: 'SnappTrip-icon' !default;
$font-family-en-number: 'sans-font-num' !default;
// stylelint-enable value-keyword-case

$font-size-html: 14px !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.429) !default;
$font-size-sm: ($font-size-base * .857) !default;
$font-size-xs: ($font-size-base * .7145) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 2 !default; //2.5
$h2-font-size: $font-size-base * 1.572 !default; //2
$h3-font-size: $font-size-base * 1.286 !default; //1.75
$h4-font-size: $font-size-base * 1.143 !default; //1.5
$h5-font-size: $font-size-base * 1 !default; //1.25
$h6-font-size: $font-size-base !default;


$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.5 !default;
$headings-color: $body-color !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;

$small-font-size: 85.75% !default;

$text-muted: $gray-80 !default;

$blockquote-small-color: $gray-80 !default;
$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: $gray-20 !default;
$hr-border-width: $border-width !default;
$hr-margin: $space-sm !default;

$mark-padding: .2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 5px !default;

$mark-bg: #fcf8e3 !default;


// Box shadow
$box-shadow-default: 0 2px 5px rgba(100, 100, 100, 0.1) !default;
$box-shadow-zindex2: 0 2px 3px rgba(0, 0, 0, 0.02) !default;
$box-shadow-zindex5: 0 5px 20px rgba(0, 0, 0, 0.07) !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: .75rem !default;
$table-cell-padding-sm: .3rem !default;

$table-bg: transparent !default;
$table-out-space: $space-lg !default;
$table-accent-bg: rgba($black, .05) !default;
$table-hover-bg: rgba($black, .075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-20 !default;

$table-head-bg: $gray-20 !default;
$table-head-color: $gray-80 !default;

$table-dark-bg: $gray-100 !default;
$table-dark-accent-bg: rgba($white, .05) !default;
$table-dark-hover-bg: rgba($white, .075) !default;
$table-dark-border-color: lighten($gray-100, 7.5%) !default;
$table-dark-color: $body-bg !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y: 0.965rem !default;
$input-btn-padding-x: 15px !default;
$input-btn-line-height: 1.75 !default;
$input-btn-min-width: 80px !default;

$input-btn-focus-width: .2rem !default;
$input-btn-focus-color: rgba(theme-color("primary"), .25) !default;
$input-btn-focus-box-shadow: none !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 10px !default;
$input-btn-line-height-sm: $input-btn-line-height !default;
$input-btn-min-width-sm: 80px !default;

$input-btn-padding-y-lg: 0.965rem !default;
$input-btn-padding-x-lg: 30px !default;
$input-btn-line-height-lg: $input-btn-line-height !default;
$input-btn-min-width-lg: 250px !default;

$input-btn-disable-bg: $gray-20 !default;
$input-btn-disable-color: $gray-20 !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: none !default;
$btn-active-box-shadow: none !default;

$btn-link-disabled-color: $input-btn-disable-color !default;

$btn-block-spacing-y: .5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $btn-border-radius !default;
$btn-border-radius-sm: $btn-border-radius !default;

$btn-transition: background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;
$btn-loading-space: -$space-xs;
$btn-loading-height: 28px;

// Forms

$input-bg: $white !default;
$input-disabled-bg: $white !default;
$input-disabled-color: $gray-20 !default;
$input-disabled-border: $gray-20 !default;

$input-color: $gray-100 !default;
$input-border-color: $gray-80 !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: none !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius !default;

$input-focus-bg: $gray-10 !default;
$input-focus-border-color: $input-color !default;
$input-focus-color: $input-color !default;

$input-placeholder-color: $gray-80 !default;

$input-height-border: $input-btn-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height)+($input-btn-padding-y * 2) !default;
//$input-height:                          calc(#{$input-height-inner} + #{$input-height-border}) !default;
$input-height: 50px !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm)+($input-btn-padding-y-sm * 2) !default;
//$input-height-sm:                       calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
$input-height-sm: 30px !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg)+($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

$input-transition: border-color ease-in-out .2s,
background ease-in-out .2s !default;

$input-checked: $gray-80 !default;


$input-font-weight: $font-weight-normal !default;
$input-font-size: $font-size-sm !default;
$label-font-size: $font-size-sm !default;

$form-text-margin-top: .25rem !default;

$form-check-margin-bottom: .5rem !default;
$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: .25rem !default;
$form-check-input-margin-x: .25rem !default;

$form-check-inline-margin-x: .75rem !default;

$form-group-margin-bottom: $space-base !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $gray-20 !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-control-gutter: 30px !default;
$custom-control-spacer-y: $space-lg !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size: 20px !default;
$custom-control-indicator-bg: $white !default;
$custom-control-indicator-color: $gray-100 !default;
$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: none !default;

$custom-control-indicator-disabled-bg: $white !default;
$custom-control-description-disabled-color: $gray-20 !default;

$custom-control-indicator-checked-color: $gray-100 !default;
$custom-control-indicator-checked-bg: theme-color("primary") !default;
$custom-control-indicator-checked-box-shadow: inset 0 0 0 3px $white !default;

$custom-control-indicator-active-box-shadow: none !default;
$custom-control-indicator-active-bg: $gray-10 !default;

$custom-control-indicator-active-color: $white !default;
$custom-control-indicator-active-bg: theme-color("primary") !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-checkbox-indicator-indeterminate-bg: theme-color("primary") !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"), "#", "%23") !default;

$custom-select-padding-y: .375rem !default;
$custom-select-padding-x: .75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-80 !default;
$custom-select-bg: $white !default;
$custom-select-disabled-bg: $gray-20 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: #333 !default;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23") !default;
$custom-select-border-width: $input-btn-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: lighten(theme-color("primary"), 25%) !default;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, .075),
0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-file-height: $input-height !default;
$custom-file-width: 14rem !default;
$custom-file-focus-box-shadow: 0 0 0 .075rem $white,
0 0 0 .2rem theme-color("primary") !default;

$custom-file-padding-y: $input-btn-padding-y !default;
$custom-file-padding-x: $input-btn-padding-x !default;
$custom-file-line-height: $input-btn-line-height !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-btn-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (placeholder: (en: "Choose file..."
  ),
  button-label: (en: "Browse"
  )) !default;


// Form validation
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: .5rem !default;
$dropdown-spacer: .125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: $gray-20 !default;
$dropdown-border-width: $border-width !default;
$dropdown-divider-bg: $gray-20 !default;
$dropdown-box-shadow: $box-shadow-zindex5;

$dropdown-link-color: $gray-80 !default;
$dropdown-link-hover-color: $gray-100 !default;
$dropdown-link-hover-bg: $dropdown-bg !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-80 !default;

$dropdown-item-padding-y: 0.536rem !default;
$dropdown-item-padding-x: $space-md !default;

$dropdown-header-color: $gray-80 !default;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Navs

$nav-link-padding-y: .5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-80 !default;

$nav-tabs-border-color: #ddd !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-20 !default;
$nav-tabs-link-active-color: $gray-80 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: #ddd !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

// Navbar

$navbar-padding-y: ($spacer / 2) !default;
$navbar-padding-x: $spacer !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y: .25rem !default;
$navbar-toggler-padding-x: .75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, .5) !default;
$navbar-dark-hover-color: rgba($white, .75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color: rgba($white, .1) !default;

$navbar-light-color: rgba($black, .5) !default;
$navbar-light-hover-color: rgba($black, .7) !default;
$navbar-light-active-color: rgba($black, .9) !default;
$navbar-light-disabled-color: rgba($black, .3) !default;
$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

// Pagination

$pagination-padding-y: 0.536rem !default;
$pagination-padding-x: 0.3rem !default;
$pagination-padding-y-sm: .25rem !default;
$pagination-padding-x-sm: .5rem !default;
$pagination-padding-y-lg: .75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;
$pagination-width: 30px !default;
$pagination-height: 30px !default;
$pagination-font-size: $font-size-sm !default;
$pagination-arrow-size: 15px !default;

$pagination-color: $gray-80 !default;
$pagination-bg: $white !default;
$pagination-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05) !default;
$pagination-border-width: 0 !default;
$pagination-border-color: #ddd !default;
$pagination-border-radius: $border-radius-lg !default;
$pagination-arrow-color: $gray-100 !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: $primary !default;
$pagination-hover-border-color: none !default;

$pagination-active-color: $white !default;
$pagination-active-bg: theme-color("primary") !default;
$pagination-active-border-color: theme-color("primary") !default;

$pagination-disabled-opacity: .3 !default;
$pagination-disabled-color: $gray-20 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: #ddd !default;


// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-bg: $gray-20 !default;


// Cards

$card-spacer-y: .75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, .125) !default;
$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg: rgba($black, .03) !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: ($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;


// Tooltips

$tooltip-max-width: 160px !default;
$tooltip-color: $white !default;
$tooltip-bg: $gray-100 !default;
$tooltip-opacity: .9 !default;
$tooltip-padding-y: 5px !default;
$tooltip-padding-x: 10px !default;
$tooltip-margin: 0 !default;


$tooltip-arrow-width: 5px !default;
$tooltip-arrow-height: 5px !default;
$tooltip-arrow-color: $tooltip-bg !default;


// Popovers

$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, .2) !default;
$popover-box-shadow: 0 .25rem .5rem rgba($black, .2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: .5rem !default;
$popover-header-padding-x: .75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: .8rem !default;
$popover-arrow-height: .4rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, .05) !default;


// Badges

$badge-font-size: $font-size-xs !default;
$badge-font-weight: $font-weight-normal !default;
$badge-padding-y: 4px !default;
$badge-padding-x: 5px !default;
$badge-border-radius: $border-radius-lg !default;

$badge-pill-padding-x: .6em !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;


// Modals

// Padding applied to the modal body
$modal-inner-padding: 30px !default;
$modal-inner-padding-xs: 60px 20px 0 !default;

$modal-dialog-margin: 10px !default;
$modal-dialog-margin-y-sm-up: 130px !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: $gray-20 !default;
$modal-content-border-width: 1px !default;
$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: $box-shadow-zindex5 !default;

$modal-backdrop-bg: rgba($black, .4) !default;
//$modal-backdrop-opacity:            .4 !default;
$modal-header-border-color: $gray-20 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: 0 !default;
$modal-footer-border-width: 0 !default;
$modal-header-padding: 0 !default;
$modal-close-bg: $gray-20 !default;
$modal-close-color: $white !default;
$modal-close-width: 24px !default;
$modal-close-font-size: 16px !default;
$modal-close-padding: 4px !default;
$modal-mobile-header-height: 60px;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform .3s ease-out !default;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $space-md !default;
$alert-padding-x: $space-md !default;
$alert-margin-bottom: $space-md !default;
$alert-color: $white !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: 0 !default;
$alert-close-size: 15px !default;



// Progress bars

$progress-height: 10px !default;
$progress-font-size: ($font-size-base * .75) !default;
$progress-bg: $gray-10 !default;
$progress-border-radius: $border-radius-lg !default;
$progress-box-shadow: none !default;
$progress-bar-color: $white !default;
$progress-bar-bg: theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width .6s ease !default;

// List group

$list-group-bg: $white !default;
$list-group-border-color: rgba($black, .125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: .75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-hover-bg: $gray-10 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-80 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-80 !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-20 !default;


// Image thumbnails

$thumbnail-padding: .25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: #ddd !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, .075) !default;
$thumbnail-transition: all .2s ease-in-out !default;


// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-80 !default;


// Breadcrumbs

$breadcrumb-padding-y: $space-xs !default;
$breadcrumb-padding-x: $space-sm !default;
$breadcrumb-item-padding: $space-sm !default;
$breadcrumb-margin-bottom: 1rem !default;
$breadcrumb-border-color: $border-color !default;
$breadcrumb-font-size: $font-size-xs !default;
$breadcrumb-bg: $white !default;
$breadcrumb-color: $gray-80 !default;
$breadcrumb-color-hover: $primary !default;
$breadcrumb-divider-color: $gray-80 !default;
$breadcrumb-active-color: $gray-100 !default;
$breadcrumb-divider: "/" !default;


// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: .5 !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

$carousel-transition: transform .6s ease !default;


// Close

$close-font-size: 15px !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: none !default;

// Code

$code-font-size: 90% !default;
$code-padding-y: .2rem !default;
$code-padding-x: .4rem !default;
$code-color: #bd4147 !default;
$code-bg: $gray-10 !default;

$kbd-color: $white !default;
$kbd-bg: $gray-100 !default;

$pre-color: $gray-100 !default;
$pre-scrollable-max-height: 340px !default;




//ela
$disabled-opacity: .5 !default;
$list-bullet-color: $primary !default;
$list-bullet-size: 5px !default;


$wizard-color: $white;
$wizard-color-active: $primary;
$wizard-color-success: $success;
$wizard-color-border: $gray-80;
$wizard-color-num: $gray-80;
$wizard-label-color: $gray-80;

//
//
//
$label-font-size: $font-size-sm !default; //11px
//$label-font-weight:     500 !default;
$label-color: $gray-100 !default;


$btn-padding-y-sm: 0.36rem !default;
$btn-line-height-sm: 1.5 !default;

// Stars
$font-size-body: 87.5%;
$star-color: $body-color !default;
$star-color-disable: $gray-80 !default;
$star-font-size: $font-size-sm !default;

$search-border-color: $border-color !default;
$search-input-margin: 5px !default;
$search-mobile-height: 50px !default;
$search-mobile-open: 320px !default;
$search-mobile-box-shadow: 0 40px 150px 0 rgba(125, 130, 137, 0.4) !default;

$stepper-height: 30px !default;
$stepper-bg: $white !default;
$stepper-color: $primary !default;
$stepper-color-hover: $white !default;
$stepper-bg-hover: $primary !default;
$stepper-color-disable: $gray-20 !default;
$stepper-padding: 2.5rem !default;

$switch-flight-font-size: $font-size-sm !default;
$switch-flight-bg: $gray-20 !default;
$switch-flight-color: $gray-20 !default;
$switch-flight-bg-active: $white !default;
$switch-flight-color-active: $body-color !default;

$flight-date-arrow: $gray-20;

$accord-padding-x: 20px !default;
$accord-padding-y: $accord-padding-x !default;
$accord-border-color: $gray-20 !default;
$accord-bg: $white !default;
$accord-hover-bg: $gray-10 !default;
$accord-icon-color: $gray-80 !default;
$accord-content-bg: $gray-10 !default;

$vertical-tab-width: 143px !default;
$vertical-tab-border-color: $gray-20 !default;
$vertical-tab-border-color-mobile: $gray-20 !default;

$mobile-filter-btn-bg: $white;
$mobile-filter-btn-color: $gray-100;
$mobile-filter-border-color: $hr-border-color;

$search-backdrop: rgba($black, .4);
$search-bg: $white;

$title-step-color: $gray-80;
$title-step-timer-color: $gray-80;


$loading-bg: $white;
$loading-color: $primary;


$datepicker-bg: $white;
$datepicker-color: $gray-100;
$datepicker-input-color: $gray-100;
$datepicker-input-size: $h4-font-size;
$datepicker-disable-color: $gray-20;
$datepicker-arrow-color: $gray-100;
$datepicker-arrow-bg: #f2f2f2;
$datepicker-week-color: $gray-20;
$datepicker-close-bg: $white;
$datepicker-close-color: $gray-100;
$datepicker-clear-color: $gray-80;
$datepicker-clear-color-hover:$gray-80;

$scrollbar-width: 5px;
$scrollbar-radius: 5px;
$scrollbar-bg: $gray-20;
$scrollbar-color: $gray-100;

$jek-header-height: 48px;

$sizes: 100;
