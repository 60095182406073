@font-face {
    font-family: orig_barlow_black;
    src: url("../fonts/logo-font.woff2") format("woff2"), url("../fonts/logo-font.woff") format("woff"), url("../fonts/logo-font.ttf") format("ttf");
}

.logo {
    font-family: orig_barlow_black;
    font-size: 42px;
    color: #52D2E3;
}
