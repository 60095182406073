// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.mw-100 { max-width: 100% !important; }
.mh-100 { max-height: 100% !important; }
.w-100{ width: 100% }
.w-50{ width: 50% }
.m-w-50{ min-width: 50% }
.m-h-35{ min-height: 35vh }
.h-100{ height: 100% }
.h-50{ height: 50%;}
.max-height{ max-height: 30vh;}
.w-12{width: 12px;}
.w-20{width: 20px;}
.min-width-40{ min-width: 40px; }
.min-width-45{ min-width: 45px; }
.full-width{ width: 100%; margin: 0; }